.main{
  .single-project-inner {
    position: relative;
    border-radius: 10px;
    margin-bottom: 25px;
    .details {
      position: absolute;
      left: 50%;
      top: 55%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 2;
      color: var(--main-color);
      visibility: hidden;
      opacity: 0;
      transition: 0.4s;
      span {
        display: block;
        margin-bottom: 10px;
      }
      a {
        color: var(--main-color);
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      bottom: 20px;
      background: rgba(255, 255, 255, 0.8);
      z-index: 1;
      border-radius: 50px;
      visibility: hidden;
      opacity: 0;
      transition: 0.4s;
    }
    &:hover {
      .details {
        top: 50%;
        visibility: visible;
        opacity: 1;
      }
      &:after {
        visibility: visible;
        opacity: 1;
        border-radius: 10px;
      }
    }
    &.style-2 {
      padding: 0 10px;
  
      .details {
        left: 0;
        bottom: -10px;
        top: auto;
        transform: translate(0);
        margin: 25px 25px 20px 25px;
        padding: 20px;
        background: #fff;
        border-radius: 10px;
        a {
          font-size: 20px;
          margin-bottom: 10px;
          display: inline-block;
          margin-top: 12px;
        }
        span {
          display: block;
          margin-bottom: 10px;
          display: inline-block;
          height: 35px;
          width: 35px;
          background: var(--main-color);
          color: #fff;
          line-height: 35px;
          border-radius: 5px;
          text-align: center;
          position: absolute;
          left: 50%;
          top: -17px;
          margin-left: -17px;
        }
      }
      &:after {
        display: none;
      }
      &:hover {
        .details {
          bottom: 0;
        }
      }
    }
    &.style-3 {
      border-radius: 0;
      overflow: hidden;
      margin-bottom: 0;
      img {
        border-radius: 0;
      }
      &:after {
        background: rgba(43, 43, 94, 0.9);
        border-radius: 0;
      }
      .details {
        position: absolute;
        left: 0;
        top: auto;
        bottom: -10px;
        text-align: left;
        transform: translate(0);
        transition: 0.4s;
        padding: 20px 30px;
        border-bottom: 65px solid var(--main-color);
        p {
          color: #fff;
        }
        h5 {
          color: #fff;
        }
        .link-wrap {
          margin-bottom: -49px;
          a {
            height: 60px;
            width: 60px;
            line-height: 60px;
            text-align: center;
            border-radius: 50%;
            background: #2b2b5e;
            display: inline-block;
            color: #fff;
          }
        }
      }
      &:hover {
        &:after {
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
        .details {
          bottom: 0;
        }
      }
    }
  }
  
}