@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100..900&display=swap');

.App {
  text-align: center;
  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.form-check-input:checked {
  background-color: rgb(34, 51, 103) !important;
  border-color: rgb(34, 51, 103) !important;
}
.pulse {
 
  border-radius: 50%;
  background: #cca92c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(47, 204, 44, 0.4);
  animation: pulse 2s infinite;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(47, 204, 44, 0.4);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgba(47, 204, 44, 0.4);
      box-shadow: 0 0 0 10px rgba(47, 204, 44, 0.4);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(47, 204, 44, 0.4)
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.bg-c{
  background-color:rgb(34, 51, 103) ;
  color: white;
}
.custom:hover .bg-y{
  background:rgb(228, 123, 40) !important ;
  color: white ;
  padding: 5px;
  font-weight: bold;
  border-radius: 18px;
  text-transform: uppercase;
}
.custom:hover .bg-g{
  background:rgb(34, 51, 103) !important ;
  color: white ;
  padding: 5px;
  font-weight: bold;
  border-radius: 20px;
  text-transform: uppercase;

}
.small-box:hover .icon>svg{
  margin-left:10px;
  transform: scale(1.5) !important;

}
.chat-i{
  color:white !important;
}
.pie p{
  color:white !important;
}
nav ul li.active{
  background:white;
  color:#223367 !important;
  border-radius:15px 0 0 15px;
} 
nav ul .active a p,  nav ul .active a i{
  color:#223367 !important;

}
.sidebar {

padding-right:0 !important;
padding-left: 0;
}
.text-success {
    color: #28a745 !important;
    font-size: 9px;
    margin-right: 5px;
}
.text-secondary {
  color: #acaeaf !important;
  font-size: 9px;
  margin-right: 5px;
}
.main-header{
  height: 86px !important;
  border: none;
  box-shadow: 0 4px 2px -2px #dfd1d1;
}

.chat-i svg{
  display: none;
}
.custom:hover svg{
  display: inline !important;
}
.color{
  color: #223367;
}
.scolor{
  color: #707070;

}
.mobile-button {
  display: none; /* Hide the button by default */
}
.dropdown-menu{
  margin:.125rem -38px 0 !important;
}
[role="cell"]{
  font-size: 18px;
  font-weight: 500;
}
[role="columnheader"]{
  font-size: 18px;
  font-weight: 800;
}
.sidebar  .active path{
  fill:#223367 !important;

}
a{
  text-decoration: none !important;
}
.in .mHisf{
  border:none !important;
}
@media (max-width: 767px) {
  /* Apply styles when the screen width is less than or equal to 767 pixels (typically mobile devices) */
  .mobile-button {
    display: block; /* Show the button */
  }
  .main-sidebar {
    overflow: scroll;
  }
  .price_compare{
    display: none;
  }
}
@media (max-width:800px) {
  /* Apply styles when the screen width is less than or equal to 767 pixels (typically mobile devices) */
  .mobile-button {
    display: block; 
    margin-right: 10px !important;

    /* Show the button */
  }
  .main-sidebar {
    overflow: scroll;
  }
}
@media (max-width:900px) {
  /* Apply styles when the screen width is less than or equal to 767 pixels (typically mobile devices) */
  .mobile-button {
    display: block; /* Show the button */
    margin-right: 10px !important;


  }
  .main-sidebar {
    overflow: scroll;
  }
}
@media (max-width:992px) {
  /* Apply styles when the screen width is less than or equal to 767 pixels (typically mobile devices) */
  .mobile-button {
    display: block; /* Show the button */
    margin-right: 10px !important;


  }
  .main-sidebar {
    overflow: scroll;
  }
}

@media (max-width: 1024px) {
  /* Apply styles when the screen width is less than or equal to 767 pixels (typically mobile devices) */
  .mobile-button {
    display: block; /* Show the button */
    margin-right: 10px !important;

  }

}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}

.logo-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.logo {
  width: 50; /* Adjust the width and height according to your logo size */
  height: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.wppBtn {
  position: fixed;
  bottom: 15px;
  right:15px;
  width: 150px;
  height: 40px;
  z-index: 999;
  max-width: 150px;
  max-height:150px;
  border-radius: 40px;
  background-color: rgb(34, 51, 103);
  
}
.mod {
  position: fixed;
  bottom: 70px;
  right:15px;
  width: 380px;
  height: auto;
  
  z-index: 999;
  max-width: 380px;
  max-height:auto;
  padding:10px;
  border-radius: 20px;
  background-color: white;

  
}

/* pulse */
.pulses {
  position: fixed;
  color:white;
  font-weight: 800;
  font-size: 21px;
}

.pulses:after {
  content: "";
  display: block;
  color:white;
  width: 150px;
  height: 40px;
  border-radius: 40px;
  border: 3px solid rgb(34, 51, 103);
  position: absolute;
  top: -3px;
  right: -3px;
  animation: pulses 2s linear infinite;
}
.cir{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin:auto;
  color:white;
  background-color: #dc3545;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;


}
.cir p{
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.cir:after {
  content: "";
  color:white;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  border: 25px solid #dc3545;
  position: absolute;
  animation: pulses 1.2s linear infinite;
  
}


.cirb{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin:auto;
  color:white;
  background-color:rgb(228, 123, 40);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;


}
.cirb p{
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.cirb:after {
  content: "";
  color:white;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  border: 25px solid rgb(228, 123, 40);
  position: absolute;
  animation: pulses 2s linear infinite;
}



.cirg{
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin:auto;
  color:white;
  background-color:rgb(34, 51, 103);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 30px;


}
.cirg p{
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.5rem;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}
.cirg:after {
  content: "";
  color:white;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin:auto;
  border: 20px solid rgb(34, 51, 103);
  position: absolute;
  animation: pulses 2s linear infinite;
}


@keyframes pulses {
  0% {
      opacity: 1;
      transform: scale(1);
  }

  80% {
      opacity: 0;
      transform: scale(1.2);
  }

  100% {
      opacity: 0;
      transform: scale(1.8);
  }
}
.inbox .chat{
  margin-top: auto;
  margin-bottom: auto;
}
.inbox .card{
  height: auto;
  border-radius: 15px !important;
}
.inbox .contacts_body{
  padding:  0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.inbox .msg_card_body{
  overflow-y: scroll;
 height: 600px;
}
.inbox .card-header{
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
  background-color:rgb(34, 51, 103);
}
.inbox .card-footer{
border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
  background-color: rgb(34, 51, 103);
  color: white;
}
.inbox .container{
  align-content: center;
}
.inbox .search{
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
}
.inbox .search:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.inbox .type_msg{
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color:white !important;
  height: 60px !important;
  overflow-y: auto;
}
.inbox .type_msg:focus{
     box-shadow:none !important;
       outline:0px !important;
}
.inbox .attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.inbox .send_btn{
border-radius: 0 15px 15px 0 !important;
background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.inbox .search_btn{
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0,0,0,0.3) !important;
  border:0 !important;
  color: white !important;
  cursor: pointer;
}
.inbox .contacts{
  list-style: none;
  padding: 0;
}
.inbox .contacts li{
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.inbox .active{
  background-color: rgba(0,0,0,0.3);
}
.inbox .user_img{
  height: 70px;
  width: 70px;
  border:1.5px solid #f5f6fa;

}
.inbox .user_img_msg{
  height: 40px;
  width: 40px;
  border:1.5px solid #f5f6fa;

}
.inbox .img_cont{
  position: relative;
  height: 70px;
  width: 70px;
}
.inbox .img_cont_msg{
  height: 40px;
  width: 40px;
}
.inbox .online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
}
.inbox .offline{
background-color: #c23616 !important;
}
.inbox .user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
}
.inbox .user_info span{
font-size: 20px;
color: white;
}
.inbox .user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
}
.inbox .video_cam{
margin-left: 50px;
margin-top: 5px;
}
.inbox .video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
}
.inbox .msg_cotainer{
margin-top: auto;
margin-bottom: auto;
margin-left: 10px;
border-radius: 25px;
background-color: rgb(34, 51, 103);
padding: 10px;
color: white;
position: relative;
min-width: 200px;
}
.inbox .msg_cotainer_send{
margin-top: auto;
margin-bottom: auto;
margin-right: 10px;
border-radius: 25px;
background-color: rgb(228, 123, 40);
color: white;
padding: 10px;
position: relative;
}
.inbox .msg_time{
position: absolute;
left: 0;
bottom: -18px;
color: rgba(138, 108, 108, 0.5);
font-size: 10px;
}
.inbox .msg_time_send{
position: absolute;
min-width: 200px;
right:0;
bottom: -15px;
color: rgba(205, 82, 82, 0.5);
font-size: 10px;
}
.inbox .msg_head{
position: relative;
}
#action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
}
.inbox .action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.inbox .action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.inbox .action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.inbox .action_menu ul li i{
padding-right: 10px;

}
.inbox .action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
 .inbox .contacts_card{
margin-bottom: 15px !important;
}
}


.parent {
  width: 450px;
  margin: auto;
  padding: 2rem;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 7px 20px 20px rgb(210, 227, 244);
}
.file-upload {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
}
.file-upload p {
  font-size: 0.87rem;
  margin-top: 10px;
  color: #bbcada;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}