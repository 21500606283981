.main{
  .single-case-study-inner {
    margin-bottom: 30px;
    .thumb {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      img {
        transform: scale(1.05);
        transition: 0.9s;
      }
    }
    .details {
      box-shadow: $box-shadow;
      padding: 25px 75px 25px 25px;
      margin-left: 50px;
      margin-top: -55px;
      position: relative;
      z-index: 2;
      background: #fff;
      border-radius: 5px 0 5px 5px;
      h5 {
        margin-bottom: 5px;
      }
      .cat {
        color: var(--main-color);
      }
      .right-arrow {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 50%;
        background: rgba(36, 107, 253, 0.1);
        display: inline-block;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        color: var(--main-color);
        &:hover {
          background: var(--main-color);
          color: #fff;
        }
      }
    }
    &:hover {
      .thumb {
        img {
          transform: scale(1.1);
        }
      }
      .details {
        .right-arrow {
          background: var(--main-color);
          color: #fff;
        }
      }
    }
  }
  
  .single-case-study-inner.style-2 {
    position: relative;
  }
  .single-case-study-inner.style-2 .details {
    position: absolute;
    left: 0;
    bottom: 0;
    background: transparent;
    width: 100%;
    margin-left: 0;
  }
  .single-case-study-inner.style-2 .details h5 {
    color: #fff;
  }
  .single-case-study-inner.style-2 .details .cat {
    color: #fff;
  }
  .single-case-study-inner:hover .thumb img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  .single-case-study-inner:hover .details .right-arrow {
    background: var(--main-color);
    color: #fff;
  }
  
}