.main{
  .faq-area-margin-top {
    margin-top: -120px;
  }
  .case-study-area {
    .animate-img-1 {
      position: absolute;
      bottom: 130px;
      left: 90px;
      width: 50px;
    }
  }
  
  .contact-inner-1 {
    margin-top: -120px;
    position: relative;
    z-index: 2;
    .animate-img-1 {
      position: absolute;
      left: -30px;
      bottom: -30px;
      z-index: -1;
    }
    .animate-img-2 {
      position: absolute;
      right: -30px;
      bottom: -30px;
    }
    .section-title {
      background: #fff;
      padding: 50px;
      margin-left: -190px;
      margin-top: 50px;
      border-radius: 5px;
      box-shadow: $box-shadow;
      .title {
        font-size: 35px;
      }
    }
  }
  
  .contact-page-list {
    margin-bottom: -110px;
    position: relative;
  }
  .contact-page-inner {
    padding: 100px;
    border-radius: 10px;
  }
  
  .contact-g-map {
    margin-bottom: -100px;
    iframe {
      width: 100%;
      height: 550px;
    }
  }
  
  .career-wrap-area {
    background: #f8f9fc;
    border-radius: 5px;
  }
  .career-nav-tab {
    border-bottom: 1px solid #ebebeb !important;
    button {
      border: 0 !important;
      border-right: 1px solid #ebebeb !important;
      background: transparent !important;
      width: 25%;
      font-weight: 600;
      color: var(--heading-color);
      padding: 15px 6px;
      &:last-child {
        border-right: 0 !important;
      }
      &.active {
        color: var(--main-color) !important;
      }
    }
  }
  .career-wrap {
    padding: 60px;
  }
  
  .project-nav-tab {
    border-bottom: 0 !important;
    background: #f8f9fc;
    padding: 5px 15px;
    border-radius: 5px;
    button {
      border: 0 !important;
      background: transparent !important;
      font-weight: 600;
      color: var(--heading-color);
      padding: 5px 15px;
      &:last-child {
        border-right: 0 !important;
      }
      &.active {
        color: var(--main-color) !important;
      }
    }
    &.style-2 {
      background: transparent;
      padding: 0;
      border-radius: 0;
      button {
        height: 60px;
        line-height: 60px;
        background: #ffffff;
        border: 1px solid #e3e3e3 !important;
        padding: 0 35px;
        border-radius: 4px;
        margin: 0 4px;
        &.active {
          background: var(--main-color) !important;
          border: 1px solid var(--main-color) !important;
          color: #fff !important;
        }
        &:hover {
          background: var(--main-color) !important;
          border: 1px solid var(--main-color) !important;
          color: #fff !important;
        }
      }
    }
  }
  
  .counter-4-area {
    position: relative;
    .counter-4-title {
      background: rgba(255, 255, 255, 0.1);
      display: inline-block;
      padding: 35px 40px;
      color: #fff;
      min-width: 200px;
    }
  }
  .about-area-margin-bottom {
    position: relative;
    margin-bottom: -120px;
    z-index: 2;
  }
  
  .navbar-area-4 {
    position: relative;
    z-index: 2;
    background: #fff;
    padding-left: 328px;
    .left-bg {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 38.4%;
    }
    .logo {
      position: relative;
      z-index: 2;
    }
    .navbar-top {
      position: relative;
      background: transparent;
      &:after {
        content: '';
        position: absolute;
        left: -20%;
        top: 0;
        background: #f8f9fc;
        height: 100%;
        width: 120%;
        z-index: -1;
      }
      ul {
        li {
          color: #010d14;
          margin-right: 15px;
          svg {
            margin-right: 4px;
          }
          a {
            color: #010d14;
          }
        }
      }
      .topbar-right {
        li {
          color: #010d14;
          margin-right: 8px;
          a {
            color: #010d14;
          }
        }
      }
    }
    .btn-base {
      color: #fff;
    }
    .navbar-nav {
      margin-left: 10px;
      li {
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
  
  .single-creator-inner {
    background: #f8f9fc;
    border-bottom: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 30px;
    margin: 0 10px;
    margin-bottom: 30px;
    .thumb {
      overflow: hidden;
      border-radius: 10px;
      img {
        transition: 0.7s;
      }
    }
    .details {
      position: relative;
      .social-media {
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: 0.4s;
        li {
          a {
            background: linear-gradient(56.64deg, #2600fc 0.04%, #ff00ea 99.88%);
          }
        }
      }
      padding-top: 25px;
    }
    &:hover {
      border-bottom: 1px solid var(--main-color3);
      .thumb {
        img {
          transform: scale(1.1);
        }
      }
      .details {
        .social-media {
          top: -16px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &.style-2 {
      background: #19164a;
      border-bottom: 0;
      .details .social-media {
        top: -47px;
      }
    }
  }
  
}