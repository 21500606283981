.main{
  .single-work-process-inner {
    border-radius: 5px;
    box-shadow: $box-shadow;
    margin-bottom: 30px;
    padding: 30px 20px;
    transition: 0.4s;
    .thumb {
      img {
        transition: 0.4s;
      }
    }
    .details {
      .process-count {
        color: var(--main-color);
        font-weight: 600;
      }
      h5 {
        font-weight: 700;
      }
      .content {
        margin-bottom: 0;
      }
    }
    &:hover {
      box-shadow: $box-shadow2;
      .thumb {
        img {
          transform: rotateY(180deg);
        }
      }
    }
  }
  
  .single-work-process-inner-2 {
    padding: 25px;
    margin-top: -90px;
    .thumb {
      height: 120px;
      width: 120px;
      line-height: 120px;
      text-align: center;
      border-radius: 5px;
      box-shadow: $box-shadow;
      display: inline-block;
      position: relative;
      background: #fff;
      img {
        transition: 0.4s;
      }
      .process-count {
        height: 30px;
        width: 30px;
        color: #fff;
        background: var(--main-color);
        border-radius: 50%;
        line-height: 030px;
        text-align: center;
        font-size: 14px;
        position: absolute;
        right: -15px;
        top: 45px;
        transition: 0.4s;
      }
    }
    .details {
      p {
        margin-bottom: 0;
      }
    }
    &:hover {
      .thumb {
        img {
          transform: rotateY(180deg);
        }
        .process-count {
          background: var(--heading-color);
        }
      }
    }
  }
  
  .single-work-process-inner-3 {
    padding: 40px;
    padding-top: 0;
    img {
      border-radius: 50%;
    }
  }
  
  .work-process-area-inner-2 {
    .row {
      .col-lg-3 {
        &:nth-child(2) {
          margin-top: 100px;
          .single-work-process-inner.style-2 .line-img {
            left: 63%;
            top: -97px;
          }
        }
        &:nth-child(4) {
          margin-top: 100px;
        }
      }
    }
  }
  
  .single-work-process-inner-4 {
    h5 {
      background: #ffffff;
      border: 2px solid #e3e3e3;
      border-radius: 10px 10px 0px 0px;
      padding: 20px 20px 30px 20px;
      margin: 0 20px;
      margin-bottom: -12px;
    }
    .thumb {
      box-shadow: $box-shadow;
      padding: 90px 20px;
      background: #fff;
      img {
        transition: 0.4s;
      }
    }
    .count {
      width: 100px;
      height: 100px;
      line-height: 100px;
      left: 415px;
      top: 5481px;
      background: #ffffff;
      border: 1px solid #e3e3e3;
      display: inline-block;
      font-size: 24px;
      color: #101a29;
      font-weight: 600;
      margin-top: -55px;
      border-radius: 50%;
      transition: 0.4s;
    }
    &:hover {
      .thumb {
        img {
          transform: rotateY(180deg);
        }
      }
      .count {
        background: var(--main-color);
        color: #fff;
        border-color: var(--main-color);
      }
    }
  }
  
  .single-work-process-inner-5 {
    padding: 25px;
    padding-top: 0;
    .thumb {
      height: 120px;
      width: 120px;
      line-height: 120px;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      background: var(--main-color);
      &.block {
        background: #ddd;
      }
      img {
        transition: 0.4s;
      }
      .process-count {
        height: 35px;
        width: 35px;
        color: var(--heading-color);
        background: #fff;
        border-radius: 50%;
        line-height: 35px;
        text-align: center;
        position: absolute;
        right: -15px;
        top: 65px;
        transition: 0.4s;
        box-shadow: $box-shadow;
        font-weight: 600;
      }
    }
    .details {
      p {
        margin-bottom: 0;
      }
    }
    &:hover {
      .thumb {
        img {
          transform: rotateY(180deg);
        }
        .process-count {
          background: var(--main-color);
        }
        &.block {
          .process-count {
            background: var(--main-color);
            color: #fff;
          }
        }
      }
    }
  }
  
}